<template>
  <div class="box p-3 box-all-votes d-flex justify-content-center flex-column align-items-center">
    <div class="font-weight-bold circle d-flex justify-content-center align-items-center flex-column text_align_start">
      <p>Nota Geral</p>
      <p class="font-weight-bold title">{{ averageVote }}</p>
    </div>
    <div class="d-flex flex-column text_align_start">
    <span class="span_info" v-for="(info, index) in information" :key="index">{{info}}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    votes: {
      type: Array
    }
  },
  data() {
    return {
      information: [
        'NPS Excelente - entre 75 e 100',
        'NPS Muito Bom - entre 50 e 74',
        'NPS Bom - entre 0 e 49',
        'NPS Ruim - entre -100 e -1',
      ],
      votesReduce: [] 
    };
  },
  computed: {
    averageVote() {
      if (this.votes.length === 0) {
        return 0;
      }
      this.votes.map(vote => this.votesReduce.push(vote.vote))
      const promot = Math.round((this.votesReduce.filter(vote => vote >= 9).length * 100) / this.votes.length);
      const detra = Math.round((this.votesReduce.filter(vote => vote <= 6).length * 100) / this.votes.length);
      return promot - detra;
    },
  }
};
</script>

<style scoped>
.box-all-votes {
  min-height: 450px;
}

.box {
  width: 330px;
  background-color: #e6e7e9;
  border-radius: 20px;
  height: 100%;
  min-height: 480px;
  gap: 20px;
}

.circle {
  width: 100%;
  height: 100%;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  color: #e6e7e9;
  font-size: 2em;
  background-color: #37aae1;
}

.circle h1 {
  font-size: 5.4em;
  margin-top: -65px;
  margin-bottom: -30px;
}

.span_info {
  font-size: 0.8em;
  }
.text_align_start {
  text-align: start;
}

.title {
  font-size: 5em;
  margin-top: -80px;
  margin-bottom: -50px;
}
</style>